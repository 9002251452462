* {
  box-sizing: border-box;
}
html {
  body {
    position: relative;
    margin: 0;
    padding: 0;
    overflow: auto;
    /* overflow: hidden !important; */
    
    & .App {
      // color: var(--blue);
      display: flex;
      flex-direction: column;
      min-height: 100vh;
      min-width: 100%;
     
      main {
        flex: 1;
        & > * {
          max-width: 1250px;
          margin: 0.5rem auto;
        }
      }
    }
  }
}
