/* vim: set shiftwidth=2 tabstop=2 autoindent cindent expandtab filetype=css: */
/*! 
 * Fancy styles for pdf2htmlEX
 * Copyright 2012,2013 Lu Wang <coolwanglu@gmail.com> 
 * https://github.com/coolwanglu/pdf2htmlEX/blob/master/share/LICENSE
 */
@keyframes fadein { from { opacity:0;} to { opacity:1;} }
@-webkit-keyframes fadein { from { opacity:0;} to { opacity:1;} }
@keyframes swing {
  0%  { transform: rotate(0deg); }
  10% { transform: rotate(0deg); }
  90% { transform: rotate(720deg); }
  100%{ transform: rotate(720deg); }
}
@-webkit-keyframes swing {
  0%  { -webkit-transform: rotate(0deg); }
  10% { -webkit-transform: rotate(0deg); }
  90% { -webkit-transform: rotate(720deg); }
  100%{ -webkit-transform: rotate(720deg); }
}
@media screen { 
  #sidebar {
    background-color:#2f3236;
    /* modified from http://philbit.com/svgpatterns/#crossstripes */
    background-image:url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0IiBoZWlnaHQ9IjQiPgo8cmVjdCB3aWR0aD0iNCIgaGVpZ2h0PSI0IiBmaWxsPSIjNDAzYzNmIj48L3JlY3Q+CjxwYXRoIGQ9Ik0wIDBMNCA0Wk00IDBMMCA0WiIgc3Ryb2tlLXdpZHRoPSIxIiBzdHJva2U9IiMxZTI5MmQiPjwvcGF0aD4KPC9zdmc+");
  }
  #outline {
    font-family:Georgia,Times,"Times New Roman",serif;
    font-size:13px;
    margin:2em 1em;
  }
  #outline ul {
    padding:0;
  }
  #outline li {
    list-style-type:none;
    margin:1em 0;
  }
  #outline li > ul {
    margin-left: 1em;
  }
  #outline a,
  #outline a:visited,
  #outline a:hover,
  #outline a:active {
    line-height:1.2;
    color:#e8e8e8;
    text-overflow:ellipsis;
    white-space:nowrap;
    text-decoration:none;
    display:block;
    overflow:hidden;
    outline:0;
  }
  #outline a:hover {
    color:rgb(0,204,255);
  }
  #page-container {
    background-color:#9e9e9e;
    /* http://philbit.com/svgpatterns/#thinstripes */
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjOWU5ZTllIj48L3JlY3Q+CjxwYXRoIGQ9Ik0wIDVMNSAwWk02IDRMNCA2Wk0tMSAxTDEgLTFaIiBzdHJva2U9IiM4ODgiIHN0cm9rZS13aWR0aD0iMSI+PC9wYXRoPgo8L3N2Zz4=");
    -webkit-transition:left 500ms;
    transition:left 500ms;
  }
  .pf {
    margin: 13px auto;
    box-shadow: 1px 1px 3px 1px #333;
    /* Needed by IE to make box-shadow works * https://developer.mozilla.org/en-US/docs/Web/CSS/box-shadow */
    border-collapse: separate;
  }
  .pc.opened { /* used by pdf2htmlEX.js, to show/hide pages */
    -webkit-animation: fadein 100ms;
    animation: fadein 100ms; 
  }
  .loading-indicator.active {
    /* 
     * use 0.01s instead of 0s,
     * since YUI Compressor will change 0s to 0,
     * which is not recognized by Firefox
     */
    -webkit-animation: swing 1.5s ease-in-out 0.01s infinite alternate none;
    animation: swing 1.5s ease-in-out 0.01s infinite alternate none;
  }
  .checked {
    background: no-repeat url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAWCAYAAADEtGw7AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAB3RJTUUH3goQDSYgDiGofgAAAslJREFUOMvtlM9LFGEYx7/vvOPM6ywuuyPFihWFBUsdNnA6KLIh+QPx4KWExULdHQ/9A9EfUodYmATDYg/iRewQzklFWxcEBcGgEplDkDtI6sw4PzrIbrOuedBb9MALD7zv+3m+z4/3Bf7bZS2bzQIAcrmcMDExcTeXy10DAFVVAQDksgFUVZ1ljD3yfd+0LOuFpmnvVVW9GHhkZAQcxwkNDQ2FSCQyRMgJxnVdy7KstKZpn7nwha6urqqfTqfPBAJAuVymlNLXoigOhfd5nmeiKL5TVTV+lmIKwAOA7u5u6Lped2BsbOwjY6yf4zgQQkAIAcedaPR9H67r3uYBQFEUFItFtLe332lpaVkUBOHK3t5eRtf1DwAwODiIubk5DA8PM8bYW1EU+wEgCIJqsCAIQAiB7/u253k2BQDDMJBKpa4mEon5eDx+UxAESJL0uK2t7XosFlvSdf0QAEmlUnlRFJ9Waho2Qghc1/U9z3uWz+eX+Wr+lL6SZfleEAQIggA8z6OpqSknimIvYyybSCReMsZ6TislhCAIAti2Dc/zejVNWwCAavN8339j27YbTg0AGGM3WltbP4WhlRWq6Q/btrs1TVsYHx+vNgqKoqBUKn2NRqPFxsbGJzzP05puUlpt0ukyOI6z7zjOwNTU1OLo6CgmJyf/gA3DgKIoWF1d/cIY24/FYgOU0pp0z/Ityzo8Pj5OTk9PbwHA+vp6zWghDC+VSiuRSOQgGo32UErJ38CO42wdHR09LBQK3zKZDDY2NupmFmF4R0cHVlZWlmRZ/iVJUn9FeWWcCCE4ODjYtG27Z2Zm5juAOmgdGAB2d3cBADs7O8uSJN2SZfl+WKlpmpumaT6Yn58vn/fs6XmbhmHMNjc3tzDGFI7jYJrm5vb29sDa2trPC/9aiqJUy5pOp4f6+vqeJ5PJBAB0dnZe/t8NBajx/z37Df5OGX8d13xzAAAAAElFTkSuQmCC);
  }
}
/* Fancy CSS END */
