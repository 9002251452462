.burger-menu {
  display: flex;
  .spacer {
    flex: 1;
  }
  .logout-btn {
    width: 100%;
    display: inline-block;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 0.5rem;
    text-decoration: none;
    &:hover {
      cursor: pointer;
      text-decoration: none !important;
      background-color: #fff;
      color: var(--blue) !important;
      border-color: #fff;
    }
  }

  // react-burger-menu

  /* Position and sizing of burger button */
  .bm-burger-button {
    outline: none;

    position: absolute;
    width: 20px;
    height: 15px;
    right: 2rem;
    top: 2rem;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    outline: none;

    background: #fff;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    outline: none;

    background: #bbbbbb;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    top: 0;
    position: fixed;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    background: var(--blue);
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
    display: flex;
    flex-direction: column;
  }

  /* Individual item */
  .bm-item {
    outline: none;
    margin-bottom: 0.5rem;
    color: #eee;
    text-decoration: none;
    display: inline-block;
    &:hover,
    &.active {
      color: #fff;
      text-decoration: underline;
    }
  }

  /* Styling of overlay */
  .bm-overlay {
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
  }
}
