.burger-menu {
  display: inline-block;
}

.makeStyles-list-1 a {
  text-decoration: none;
}

.MuiListItem-gutters {
  color: #0077c1
}

.MuiListItem-gutters:hover {
  background: whitesmoke;
}

